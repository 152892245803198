<template>
    <!--  -->
    <div style="background-color: #7552cc;">
        <div class="container mx-auto p-4">
            <form @submit.prevent="submitForm">
                <!-- Section 1 -->
                <div class="mb-8 bg-gray-100 p-6 rounded shadow">
                    <h2 class="text-xl font-semibold mb-4">Section 1: Personal Information</h2>
                    <!-- Question 1 -->
                    <div class="mb-8">
                        <label for="default-input" class="block text-lg font-semibold mb-4">What is your name?</label>
                        <input type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    </div>
                    <!-- Question 2 y 3 -->
                    <div class="flex flex-row flex-nowrap justify-between items-center">
                        <div class="w-1/2 pr-2 flex flex-col items-center">
                            <label for="genderSelect" class="block text-lg font-semibold mb-4">What is your gender?</label>


                            <select id="genderSelect" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Choose your gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Non-Binary">Non-Binary</option>
                                <option value="Prefer Not to Say">Prefer Not to Say</option>
                            </select>
                        </div>
                        <div class="w-1/2 pl-2 flex flex-col items-center">
                            <!-- Age Range -->
                            <label for="ageSelect" class="block text-lg font-semibold mb-4">What is your age range?</label>

                            <select id="ageSelect" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Choose an age range</option>
                                <option value="Under 18">Under 18</option>
                                <option value="18-24">18-24</option>
                                <option value="25-34">25-34</option>
                                <option value="35-44">35-44</option>
                                <option value="45-54">45-54</option>
                                <option value="55-64">55-64</option>
                                <option value="65-74">65-74</option>
                                <option value="75 and older">75 and older</option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- Section 2 -->
                <div class="mb-8 bg-gray-100 p-6 rounded shadow">
                    <h2 class="text-xl font-semibold mb-4">Section 2: Satisfaction</h2>
                    <!-- Question 3 -->
                    <div class="mb-8">
                        <h3 class="block text-lg font-semibold mb-4">How would you describe the flavor of an apple?</h3>
                        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                <div class="flex items-center pl-3">
                                    <input id="horizontal-list-radio-license" type="radio" value="" name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                    <label for="horizontal-list-radio-license" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Very Bad </label>
                                </div>
                            </li>
                            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                <div class="flex items-center pl-3">
                                    <input id="horizontal-list-radio-id" type="radio" value="" name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                    <label for="horizontal-list-radio-id" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Bad</label>
                                </div>
                            </li>
                            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                <div class="flex items-center pl-3">
                                    <input id="horizontal-list-radio-id2" type="radio" value="" name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                    <label for="horizontal-list-radio-id2" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Neutral</label>
                                </div>
                            </li>
                            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                <div class="flex items-center pl-3">
                                    <input id="horizontal-list-radio-millitary" type="radio" value="" name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                    <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Good</label>
                                </div>
                            </li>
                            <li class="w-full dark:border-gray-600">
                                <div class="flex items-center pl-3">
                                    <input id="horizontal-list-radio-passport" type="radio" value="" name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                    <label for="horizontal-list-radio-passport" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Very Good</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- Question 4 -->
                    <div class="mb-8">
                        <h3 class="block text-lg font-semibold mb-4">How would you describe the flavor of a pear?</h3>
                        <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                <div class="flex items-center pl-3">
                                    <input id="horizontal-list-radio-license2" type="radio" value="a" name="pear-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                    <label for="horizontal-list-radio-license2" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Very Bad </label>
                                </div>
                            </li>
                            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                <div class="flex items-center pl-3">
                                    <input id="horizontal-list-radio-id2" type="radio" value="b" name="pear-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                    <label for="horizontal-list-radio-id2" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Bad</label>
                                </div>
                            </li>
                            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                <div class="flex items-center pl-3">
                                    <input id="horizontal-list-radio-id2w" type="radio" value="" name="pear-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                    <label for="horizontal-list-radio-id2w" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Neutral</label>
                                </div>
                            </li>
                            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                <div class="flex items-center pl-3">
                                    <input id="horizontal-list-radio-millitary2" type="radio" value="" name="pear-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                    <label for="horizontal-list-radio-millitary2" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Good</label>
                                </div>
                            </li>
                            <li class="w-full dark:border-gray-600">
                                <div class="flex items-center pl-3">
                                    <input id="horizontal-list-radio-passport2" type="radio" value="" name="pear-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                    <label for="horizontal-list-radio-passport2" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Very Good</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Section 3 -->
                <div class="mb-8 bg-gray-100 p-6 rounded shadow">
                    <h2 class="text-xl font-semibold mb-4">Section 3: Experience</h2>
                    <!-- Question 5 -->
                    <div class="mb-8">
                        <div>
                            <label for="minmax-range" class="block text-lg font-semibold mb-4">
                                How do you find your overall experience?
                            </label>
                            <div class="flex flex-col items-center">
                                <span class="mb-2 text-lg">{{ sliderValue }}</span>
                                <input id="minmax-range"
                                       type="range"
                                       min="0"
                                       max="10"
                                       v-model="sliderValue"
                                       class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Submit Button -->
                <button type="submit" class="bg-green-500 hover:bg-green-600 text-white p-2 rounded">
                    Submit
                </button>
            </form>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { onMounted } from 'vue';
    import { initFlowbite } from 'flowbite';
    onMounted(() => {
        initFlowbite();
    });
</script>

<script>
    export default {
        data() {
            return {
                sliderValue: 5, // Initialize sliderValue to 5
            };
        },
    };

    document.addEventListener('DOMContentLoaded', function () {
        // Add event listeners for both dropdown buttons
        document.getElementById('dropdownDividerButton').addEventListener('click', toggleDropdown);
        document.getElementById('ageDropdownButton').addEventListener('click', toggleDropdown);

        function toggleDropdown(event) {
            const toggleId = event.currentTarget.getAttribute('data-dropdown-toggle');
            const dropdown = document.getElementById(toggleId);

            if (dropdown.classList.contains('hidden')) {
                dropdown.classList.remove('hidden');
            } else {
                dropdown.classList.add('hidden');
            }
        }
    });

</script>

<style scoped>
    /* Additional styles here if needed */
</style>
