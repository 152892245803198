<template>
    <!-- Topbar-->
    <topBarDashboard_simple></topBarDashboard_simple>
    <!-- Sidebar -->
    <navBarDashboard_simple></navBarDashboard_simple>

    <main class="p-4 md:ml-64 h-auto pt-20 bg-gray-50 dark:bg-gray-900 min-h-screen">
        <div v-if="loading" class="px-3 py-1 text-xs font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200">loading...</div>

        <div class="bg-gray-50 dark:bg-gray-900 min-h-screen">


            <div class="relative overflow-x-auto">
                <table v-if="paginatedUsers.length" class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">


                        <!-- <table v-if="paginatedUsers.length" class="min-w-full bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-300"> -->
                        <!---<thead> -->
                        <tr>
                            <th class="px-6 py-3"></th>
                            <th class="px-6 py-3">Name</th>
                            <th class="px-6 py-3">Email</th>
                            <th class="px-6 py-3">Role</th>
                            <th class="px-6 py-3">Details</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in paginatedUsers" :key="user['e-mail']" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <td class="flex justify-center items-center my-1"> <ProfileAvatar :username="user.name + ' ' + user.lastName" size="s"></ProfileAvatar> </td>
                            <td class="px-6 py-4">{{ user.name + ' ' + user.lastName }}</td>
                            <td class="px-6 py-4">{{ user['e-mail'] }}</td>
                            <td class="px-6 py-4">{{ user.role }}</td>
                            <td class="px-6 py-4">
                                <!-- Using router-link to redirect -->
                                <router-link :to="{ name: 'modifyDetails', params: { userId: user['e-mail'] } }" class="text-primary-600 hover:underline dark:text-primary-500">
                                    Modify
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- Pagination controls using numbers -->
                <div class="flex mt-4 justify-center">
                    <button @click="currentPage--" :disabled="currentPage === 1" class="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" :class="{ 'cursor-not-allowed': currentPage === 1 }">Prev</button>
                    <button v-for="n in totalPages" :key="n" @click="currentPage = n" :disabled="currentPage === n" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" :class="{ 'text-blue-500': currentPage === n, 'cursor-not-allowed': currentPage === n }">{{ n }}</button>
                    <button @click="currentPage++" :disabled="currentPage === totalPages" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" :class="{ 'cursor-not-allowed': currentPage === totalPages }">Next</button>
                </div>


                <p v-if="error">{{ error }}</p>
            </div>
        </div>
    </main>
</template>

<script>
    import { ref, computed, onMounted } from 'vue';
    import axios from 'axios';
    import navBarDashboard_simple from '../../components/DashBoard/navBarDashboard_simple';
    import topBarDashboard_simple from '../../components/DashBoard/topBarDashboard_simple';
    import ProfileAvatar from 'vue-profile-avatar';


    export default {
        components: {
            navBarDashboard_simple,
            topBarDashboard_simple,
            ProfileAvatar,
        },



        setup() {

            const users = ref([]);
            const error = ref(null);
            const itemsPerPage = ref(10);
            const currentPage = ref(1);
            const loading = ref(false);


            onMounted(async () => {


                loading.value = true;
                try {
                    const url = 'https://ls41fjkzva.execute-api.us-east-2.amazonaws.com/dev_ops/';  // Replace with your Lambda API Gateway URL
                    const response = await axios.get(url);
                    users.value = JSON.parse(response.data.body);
                } catch (err) {
                    error.value = 'There was a problem fetching the data';
                }
                finally {
                    loading.value = false;
                }

            });





            const totalPages = computed(() => Math.ceil(users.value.length / itemsPerPage.value));

            const paginatedUsers = computed(() => {
                const start = (currentPage.value - 1) * itemsPerPage.value;
                const end = start + itemsPerPage.value;
                return users.value.slice(start, end);
            });

            return { users, error, paginatedUsers, currentPage, totalPages, loading };
        },
    };</script>

