<template>

    <div class="antialiased bg-gray-50 dark:bg-gray-900">
        <!-- Topbar-->
        <topBarDashboard_simple></topBarDashboard_simple>
        <!-- Sidebar -->
        <navBarDashboard_simple></navBarDashboard_simple>

        <main class="p-4 md:ml-64 h-auto pt-20">

            <section class="bg-gray-50 dark:bg-gray-900 overflow-auto">
                <div class="min-h-screen flex items-center justify-center"> <!-- Para centrar-->
                    <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Create a general account
                            </h1>

                            <form class="space-y-4 md:space-y-6" @submit.prevent="checkPasswords">

                                <div>
                                    <label for="userName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                                    <input type="text" id="userName" v-model="userName" name="userName" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required="">
                                </div>

                                <div>
                                    <label for="lastName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                                    <input type="text" id="lastName" v-model="lastName" name="lastName" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required="">
                                </div>


                                <div>
                                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">E-mail</label>
                                    <input type="email" v-model="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="">
                                </div>
                                <p v-if="emailDuplicated" class="text-sm text-red-500">The provided email address is already associated with a registered account. Try another.</p>

                                <div>
                                    <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input v-model="password" type="password" name="password" id="password" placeholder="" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                                </div>
                                <div>
                                    <label for="confirmPassword" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                                    <input v-model="confirmPassword" type="password" name="confirmPassword" id="confirmPassword" placeholder="" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                                </div>
                                <p v-if="passwordsMismatch" class="text-sm text-red-500">Passwords do not match.</p>

                                <div class="mt-4">
                                    <label for="role" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Role</label>
                                    <select id="role" name="role" v-model="role" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option value="developer">developer</option>
                                        <option value="creator">creator</option>
                                        <option value="member">member</option>
                                    </select>
                                </div>



                                <button type="submit" class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Create an account</button>


                            </form>


                            


                        </div>
                    </div>
                </div>
            </section>


        </main>
    </div>
</template>


<script>

    

    import { ref } from 'vue';
    import axios from 'axios';

    import { useRouter } from 'vue-router';
    import navBarDashboard_simple from '../../components/DashBoard/navBarDashboard_simple';
    import topBarDashboard_simple from '../../components/DashBoard/topBarDashboard_simple';

    export default {
        name: "signup_dev_creators",

        components: {
            navBarDashboard_simple,
            topBarDashboard_simple,
            
        },

        setup() {
            const email = ref('');
            const password = ref('');
            const userName = ref('');
            const lastName = ref('');

            const role = ref('');

            const confirmPassword = ref('');
            const passwordsMismatch = ref(false);
            const emailDuplicated = ref(false);
            const router = useRouter();

            const checkPasswords = () => {
                if (password.value !== confirmPassword.value) {
                    passwordsMismatch.value = true;
                } else {
                    passwordsMismatch.value = false;
                    submitForm();
                }
            };

            const submitForm = async () => {
                try {
                    const response = await axios.post('https://nkccbv78ob.execute-api.us-east-2.amazonaws.com/dev_ops/', {
                        email: email.value,
                        password: password.value,
                        userName: userName.value,
                        lastName: lastName.value,
                        role: role.value,
                    });


                    if (response.data.statusCode === 200) {
                        router.push('/dashboard_solutions');
                    } else if (response.data.statusCode === 400) {
                        emailDuplicated.value = true;
                    }

                } catch (error) {
                    console.error(error);
                }
            }
            return { email, password, userName, lastName, confirmPassword, passwordsMismatch, checkPasswords, emailDuplicated, role};

            onMounted(() => {
                initFlowbite();
            });

        }
    };
</script>


