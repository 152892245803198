<template>
    <header_template />
    <section class="bg-white dark:bg-gray-900 h-screen">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                <!--<img class="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo"> -->
            </a>
            <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        Login into your account
                    </h1>


                    <form class="space-y-4 md:space-y-6" @submit.prevent="checkPasswords">
                        <div class="mb-6">
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                            <input type="email" v-model="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required>
                            <p v-if="notUser" class="text-sm text-red-500">User does not exist.</p>
                        </div>
                        <div class="mb-6">
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
                            <input v-model="password" type="password" name="password" id="password" placeholder="" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                            
                            <p v-if="incorrectedPass" class="text-sm text-red-500">Incorrected Password.</p>
                        </div>
                       
                        <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </section>

</template>


<script>
    import { ref } from 'vue';
    import header_template from '../components/header_template.vue'

    import axios from 'axios';
    import { useRouter } from 'vue-router';

    import { useStore } from 'vuex';
    import VueCookies from 'vue-cookies';
    

    export default {
        components: {
            header_template
        },

        setup() {
            const router = useRouter();

            // Check if there is a cookie
            const userEmail = VueCookies.get('userEmail');


            if (userEmail) {
                router.push('/dashboard_solutions');
            }

            const email = ref('');
            const password = ref('');
            const loginError = ref(false);
            
            const incorrectedPass = ref(false);
            const passNull = ref(false);
            const notUser = ref(false);

            const store = useStore();  // Access the Vuex store

            const checkPasswords = () => {
                if (!password.value) {
                    passNull.value = true;
                } else {
                    submitForm();
                }
            };

            const submitForm = async () => {
                try {
                    const response = await axios.post('https://pklcs5hzrl.execute-api.us-east-2.amazonaws.com/dev_ops/', {
                        email: email.value,
                        password: password.value,
                    });
                    

                    if (response.data.statusCode === 200) {

                        const user_data = JSON.parse(response.data.body);


                        const userDetails = () => {
                            store.commit('setUserData', user_data);  // Commit the setUser mutation
                        };

                        userDetails();

                        const fullName = user_data.name + ' ' + user_data.lastName;

                        VueCookies.set('userEmail', email.value, { expires: 1 });
                        VueCookies.set('fullName', fullName, { expires: 1 });

                        router.push('/dashboard_solutions');
                        // router.push('/borrar');


                    }

                    else if (response.data.statusCode === 400) {
                        loginError.value = true;
                        notUser.value = true;
                    }
                    else if (response.data.statusCode === 402) {
                        loginError.value = true;
                        incorrectedPass.value = true;
                    }

                } catch (error) {
                    console.error(error);
                }
            }

            return { email, password, loginError, submitForm, incorrectedPass, passNull, checkPasswords, notUser};


        }
    };
</script>

