import { createStore } from 'vuex'

export default createStore({
    state: {
        user_data: null,
    },

    mutations: {

        setUserData(state, user_data) {
            state.user_data = user_data;
            //"time_stamp", "e-mail", "name", "lastName"
        
        }
    },

    getters: {
        getUserData: state => state.user_data,
    },

    actions: {
    },

    modules: {
  }
})
