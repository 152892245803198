<template>

    <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">List of projects:</h2>
    
    <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
        <div v-for="project in projects" :key="project.id">
            <router-link :to="`/project/${project.id}`">{{ project.name }}</router-link>
        </div>
    </ul>

</template>

<script>export default {
  data() {
    return {
      projects: [
        { id: 1, name: "Project 1" },
        { id: 2, name: "Project 2" },
        { id: 3, name: "Project 3" }
      ]
    };
  }
};</script>
