<template>
    <div>
        <form @submit.prevent="handleSubmit">
            <div v-for="question in questions" :key="question.id">
                <p>{{ question.text }}</p>
                <button @click="startRecording(question.id)">Start Recording</button>
                <button @click="stopRecording(question.id)">Stop Recording</button>
                <button @click="playAudio(question.id)">Play</button>
                <button @click="deleteAudio(question.id)">Delete</button>

                <div v-if="playingAudioId === question.id" class="progress-container">
                    <div :style="{width: `${progressPercentage}%`}"></div>
                </div>
            </div>
            <button type="submit">Submit</button>
        </form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                mediaRecorder: null,
                audioChunks: [],
                currentQuestion: null,
                recordings: {},
                questions: [
                    { id: 1, text: 'How are you?' },
                    { id: 2, text: 'What is your favorite color?' },
                    { id: 3, text: 'Do you like coding?' },
                ],
                playingAudioId: null,
                progressPercentage: 0,
            };
        },
        methods: {
            handleSubmit() {
                // Submit form
            },
            startRecording(questionNumber) {
                navigator.mediaDevices.getUserMedia({ audio: true })

                    .then(stream => {
                        this.mediaRecorder = new MediaRecorder(stream);
                        this.mediaRecorder.ondataavailable = event => {
                            this.audioChunks.push(event.data);

                        };
                        this.mediaRecorder.onstop = () => {
                            const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
                            this.recordings[this.currentQuestion] = URL.createObjectURL(audioBlob);
                        };
                        this.currentQuestion = questionNumber;
                        this.audioChunks = [];
                        this.mediaRecorder.start();
                    });
            },
            stopRecording() {
                if (this.mediaRecorder) {
                    this.mediaRecorder.stop();
                }
            },
            playAudio(questionNumber) {

                const audio = new Audio(this.recordings[questionNumber]);

                this.playingAudioId = questionNumber;

                // Reset progress percentage to 0
                this.progressPercentage = 0;

                // Play audio
                audio.play();

                // Update progress bar while audio is playing
                audio.addEventListener("timeupdate", () => {
                    this.progressPercentage = (audio.currentTime / audio.duration) * 100;
                });

                // Reset progress bar and playingAudioId when audio ends
                audio.addEventListener("ended", () => {
                    this.progressPercentage = 0;
                    this.playingAudioId = null;
                });
            },
            deleteAudio(questionNumber) {
                // Delete audio logic here
                delete this.recordings[questionNumber];
                this.playingAudioId = null;
            },
        },
    };
</script>

<style>
    .progress-container {
        width: 100%;
        height: 10px;
        background-color: grey;
    }

        .progress-container div {
            height: 100%;
            background-color: green;
        }
</style>
