<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
        <!-- Topbar-->
        <topBarDashboard_simple></topBarDashboard_simple>
        <!-- Sidebar -->
        <navBarDashboard_simple></navBarDashboard_simple>

        <!-- Core pages -->
        <!-- ########## -->
        <!-- ########## -->

        <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Inbox - Contact Us Messages</h2>
        </div>

        <!-- ########## -->
        <!-- ########## -->
    </div>
</template>

<script>
    import VueCookies from 'vue-cookies';
    import { useRouter } from 'vue-router';
    import { ref, onMounted, computed } from 'vue';


    import navBarDashboard_simple from '../../components/DashBoard/navBarDashboard_simple';
    import topBarDashboard_simple from '../../components/DashBoard/topBarDashboard_simple';

    // import footer_template from '../../components/footer_template';


    export default {
        components: {
            navBarDashboard_simple,
            topBarDashboard_simple,

        },
        name: 'constructionTimeLine',
        setup() {
            const router = useRouter();
            const userEmail = VueCookies.get('userEmail');
            const name = VueCookies.get('name') || 'Unknown';

            onMounted(() => {
                initFlowbite();
            });

            if (!userEmail) {
                router.push('/login');
            }

            const logout = () => {
                VueCookies.remove('userEmail');
                VueCookies.remove('name');
                router.push('/');
            };



            return { userEmail, name, logout };
        },

    };
</script>

