<template>

    <header_template />

    <section class="bg-white dark:bg-gray-900 h-screen">
        <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Contact Us</h2>
            <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
                 If you have any questions, comments, or suggestions, don't hesitate to reach out.
            </p>
            <form @submit.prevent="checkMessages" class="space-y-8">
                <div>
                    <label for="input_email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
                    <input v-model="input_email" type="email" id="input_email" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@company.com" required>
                    <p v-if="empty_email" class="text-red-600 text-sm">Please provide an email address.</p>
                </div>
                <div>
                    <label for="input_subject" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Subject</label>
                    <input v-model="input_subject" type="text" id="input_subject" class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Ask anything..." required>
                    
                </div>
                <div class="sm:col-span-2">
                    <label for="text_message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
                    <textarea v-model="text_message" id="text_message" rows="6" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a comment..."></textarea>
                    <p v-if="empty_message" class="text-red-600 text-sm">Please leave a message so we can reply to you.</p>
                </div>


                <!--- <button type="submit" class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send message</button> -->
                <div class="flex justify-center m-5">
                    <button id="successButton" class="block text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" type="submit">
                        Send message
                    </button>
                </div>

            </form>
        </div>
    </section>


</template>


<script>
    import { ref, onMounted } from 'vue';
    import header_template from '../components/header_template.vue'
    import footer_template from '../components/footer_template.vue'
    import axios from 'axios';
    import { useRouter } from 'vue-router';


    export default {
        components: {
            header_template,
            footer_template,
        },

        setup() {
            const input_email = ref('');
            const input_subject = ref('');
            const text_message = ref('');

            const empty_email = ref(false);
            const empty_message = ref(false);
            const router = useRouter();

            onMounted(() => {
                initFlowbite();
            });

            const checkMessages = () => {
                if (!input_email.value) {
                    empty_email.value = true;
                } else if (!text_message.value) {
                    empty_message.value = true;
                } else {
                    submitForm();
                }
            };

            const submitForm = async () => {
                try {
                    const response = await axios.post('https://ti65s7ou47.execute-api.us-east-2.amazonaws.com/dev_ops', {
                        email: input_email.value,
                        subject: input_subject.value,
                        message: text_message.value,
                    });

                    if (response.data.statusCode === 200) {

                        router.push('/successEmail').then(() => {
                            // Do something after navigation, if needed
                        });
                    }
                   
                } catch (error) {
                    console.error(error);
                }
            }

            return { input_email, input_subject, text_message, checkMessages, submitForm, empty_email, empty_message }
        }
    };
</script>