<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
        <!-- Topbar-->
        <topBarDashboard_simple></topBarDashboard_simple>
        <!-- Sidebar -->
        <navBarDashboard_simple></navBarDashboard_simple>


        <!-- Core pages -->
        <!-- ########## -->
        <!-- ########## -->
        <main class="p-4 md:ml-64 h-screen pt-20 ">
            <div class="grid md:grid-cols-3 gap-4 dark:bg-gray-800">
                <!-- Project 1 -->
                <div class="px-2 bg-white shadow-lg rounded-lg overflow-hidden my-4 dark:bg-gray-700">
                    <img class="w-full h-56 object-cover" src="../images/light.jpg" alt="project-1">
                    <div class="p-4 dark:text-gray-300">
                        <h1 class="text-xl font-bold text-gray-700 dark:text-white">
                            <a href="https://project-1-link.com">Project Demo 1</a>
                        </h1>
                        <p class="mt-2 text-gray-600 dark:text-gray-400 text-justify">This project is a comprehensive showcase of the innovative solutions that this website provides.</p>
                    </div>
                </div>

                <!-- Project 2 -->
                <div class="px-2 bg-white shadow-lg rounded-lg overflow-hidden my-4 dark:bg-gray-700">
                    <img class="w-full h-56 object-cover" src="../images/gear.jpg" alt="project-2">
                    <div class="p-4 dark:text-gray-300">
                        <h1 class="text-xl font-bold text-gray-700 dark:text-white">
                            <a href="https://project-2-link.com">Project Demo 2</a>
                        </h1>
                        <p class="mt-2 text-gray-600 dark:text-gray-400 text-justify">This project is a comprehensive showcase of the innovative solutions that this website provides.</p>
                    </div>
                </div>

                <!-- Project 3 -->
                <div class="px-2 bg-white shadow-lg rounded-lg overflow-hidden my-4 dark:bg-gray-700">
                    <img class="w-full h-56 object-cover" src="../images/code.jpg" alt="project-3">
                    <div class="p-4 dark:text-gray-300">
                        <h1 class="text-xl font-bold text-gray-700 dark:text-white">
                            <a href="https://project-3-link.com">User's trust in Large Language models</a>
                        </h1>
                        <p class="mt-2 text-gray-600 dark:text-gray-400 text-justify">This projects explores the relation of LLMs and users.</p>
                    </div>
                </div>
            </div>


        </main>
        <!-- Dark Mode Section -->
        <div class="bg-gray-50 dark:bg-gray-800">
            <!-- The content you want to display in dark mode -->
        </div>


    </div>


</template>

<script>
    import VueCookies from 'vue-cookies';
    import { useRouter } from 'vue-router';


    import navBarDashboard_simple from '../components/DashBoard/navBarDashboard_simple';
    import topBarDashboard_simple from '../components/DashBoard/topBarDashboard_simple';

    // import VueApexCharts from "vue3-apexcharts";

    export default {
        components: {
            navBarDashboard_simple,
            topBarDashboard_simple,
 
        },
        name: 'dashboard_solutions',
        setup() {
            const router = useRouter();
            const userEmail = VueCookies.get('userEmail');
            const name = VueCookies.get('name') || 'Unknown';


            if (!userEmail) {
                router.push('/login');
            }

            const logout = () => {
                VueCookies.remove('userEmail');
                VueCookies.remove('name');
                router.push('/');
            };



            return { userEmail, name, logout};
        },
    };
</script>
