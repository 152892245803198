<template>
    <div class="flex flex-col h-screen relative">
        <div ref="chatContainer" class="absolute inset-0 overflow-y-auto p-4 pb-20">
            <div class="flex flex-col items-start">
                <div v-for="message in messages" :key="message.id" class="my-2">
                    <div class="py-2 px-4 rounded bg-blue-200">
                        {{ message.text }}
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed bottom-0 left-0 w-full border-t border-gray-200 z-10">
            <input v-model="newMessage"
                   @keyup.enter="sendMessage"
                   class="w-full p-4"
                   placeholder="Type your message here..." />
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                newMessage: "",
                messages: [],
            };
        },
        methods: {
            sendMessage() {
                if (!this.newMessage.trim()) return;

                const newMessage = {
                    id: Date.now(),
                    text: this.newMessage.trim(),
                };

                this.messages.push(newMessage);
                this.newMessage = "";

                this.$nextTick(() => {
                    this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight;
                });
            },
        },
        mounted() {
            // Scroll to bottom initially
            this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight;
        },
    };
</script>

<style scoped>
</style>
