<template>
    <div class="flex flex-col h-screen relative">
        <header_public_user></header_public_user>

        <main ref="mainElement" id="messageContainer" class="flex flex-col p-4 h-auto pt-20 pl-32 pr-32 pb-20 overflow-auto space-y-4">
            <p> What is your current concern about Sustainability?</p>
            <div v-for="index in Object.keys(gpt4Responses)" :key="'past-' + index" class="p-4 border-b">
                <div style="background-color: #edf6f9;" class="p-2 rounded">
                    <p><strong>You asked:</strong> {{ userInput[index] }}</p>
                </div>
                <div style="background-color: #83c5be;" class="p-2 rounded mt-2">
                    <p><strong>Server says:</strong> {{ gpt4Responses[index] }}</p>
                </div>
            </div>
        </main>

        <footer class="fixed bottom-0 left-0 w-full p-4 grid grid-cols-3" style=" min-height: 4rem;">
            <div class="text-black">
                <!-- First part (Left) -->
            </div>
            <div class="text-black flex justify-center items-center">
                <!-- Second part (Middle) -->
                <div v-if="activeFormIndex <= totalForms" class="flex w-full">
                    <form @submit.prevent="handleSubmit(activeFormIndex)" class="flex space-x-4 w-full">
                        <textarea :id="'userInput' + activeFormIndex"
                                  v-model="userInput[activeFormIndex]"
                                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Reply..."
                                  rows="1"></textarea>
                        <button type="submit" class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800">Submit</button>
                    </form>
                </div>
                <div v-else class="w-full bg-gray-400 p-4 rounded" style="background-color: #7552cc;">
                    <p class="text-white">All forms have been submitted.</p>
                </div>
            </div>
            <div class="text-black">
                <!-- Third part (Right) -->
            </div>
        </footer>


    </div>
</template>

<script>
    import VueCookies from 'vue-cookies';
    import { ref, onMounted, computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import header_public_user from './../../header_public_user.vue';

    export default {
        name: 'project_talk',
        components: {
            header_public_user,
        },
        data() {
            return {
                totalForms: 7,
                activeFormIndex: 1,
                userInput: {},
                gpt4Responses: {},
                dummyGPT4Data: {
                    1: "I'm fine, thank you. How about you?",
                    2: "My favorite color is blue.",
                    3: "Yes, I love coding. It's a way to solve problems and be creative.",
                    4: "Yes, I love coding.",
                    5: "Yes, Again I love coding.",
                    6: "Yes, Again I love coding.",
                    7: "Yes, Again I love coding."
                }
            };
        },
        methods: {
            handleSubmit(index) {
                this.gpt4Responses[index] = this.dummyGPT4Data[index];
                this.activeFormIndex = (this.activeFormIndex < 8) ? this.activeFormIndex + 1 : 9;

                this.$nextTick(() => {
                    let container = document.getElementById("messageContainer");
                    if (container) {
                        container.scrollTop = container.scrollHeight;
                    }
                });
            },
        },

        setup() {
            const router = useRouter();
            const userEmail = VueCookies.get('userEmail');

            if (!userEmail) {
                router.push('/login');
            }

            const name = VueCookies.get('name') || 'Unknown';
            const dropdownOpen = ref(false);

            const logout = () => {
                VueCookies.remove('userEmail');
                VueCookies.remove('name');
                router.push('/');
            };

            return { userEmail, name, logout, dropdownOpen };
        },
    };
</script>
