import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import vue3GoogleLogin from 'vue3-google-login'



createApp(App).use(store).use(router).use(vue3GoogleLogin, {
    clientId: '364009776344-nee3n50k5sq06dqcb7ek5voejk7ptm1p.apps.googleusercontent.com'
}).mount('#app')