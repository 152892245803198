<template>

    <!-- Topbar-->
    <topBarDashboard_simple></topBarDashboard_simple>
    <!-- Sidebar -->
    <navBarDashboard_simple></navBarDashboard_simple>

    <main class="p-4 md:ml-64 h-auto pt-20 bg-gray-50 dark:bg-gray-900 min-h-screen">

        <div v-if="loading" class="px-3 py-1 text-xs font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200">loading...</div>


        <div class="p-6 max-w-lg mx-auto">
            <div v-if="userBio" class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                <h1 class="text-2xl font-bold mb-6 text-gray-900 dark:text-white">Update User Details</h1>
                <form @submit.prevent="updateUser">

                    <div class="mb-4">
                        <label class="text-gray-600 dark:text-gray-400">Name:</label>
                        <input v-model="userBio.name" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" />
                    </div>

                    <div class="mb-4">
                        <label class="text-gray-600 dark:text-gray-400">Last Name:</label>
                        <input v-model="userBio.lastName" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" />
                    </div>

                    <div class="mb-4">
                        <label for="role" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Role</label>
                        <select v-model="userBio.role" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="creator">creator</option>
                            <option value="developer">developer</option>
                            <option value="member">member</option>
                        </select>
                    </div>

                    <div class="mb-4">
                        <span class="text-gray-600 dark:text-gray-400">Registered:</span> <span class="text-gray-900 dark:text-white">{{ userBio.time_stamp }}</span>
                    </div>
                    <div class="flex justify-center">
                        <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Update
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <!--- Projects --->
        <div class="p-6 max-w-lg mx-auto">
            <!-- Existing projects display -->
            <div v-if="sortedProjects && sortedProjects.length && noProject !== true" class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Projects:</h2>
                <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400" v-for="project in sortedProjects" :key="project">
                    <li>{{ project }}</li>
                </ul>
            </div>

            <!-- 'No projects' display -->
            <div v-if="noProject === true && userBio" class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                <h1 class="text-2xl font-bold mb-6 text-gray-900 dark:text-white">Projects</h1>
                <p class="text-gray-900 dark:text-white">{{ userBio.name + ' ' + userBio.lastName  }} has no projects associated.</p>
            </div>

            <!-- Add project dropdown and button -->
            <!-- Conditional role form -->
            <!-- Button to toggle role form -->

            <div class="p-6 max-w-lg mx-auto" v-if="show_buttom_1 == true">
                <div class="flex justify-center">

                    <button @click="showRoleForm = !showRoleForm" class="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        {{ showRoleForm ? 'Cancel' : 'Add Project' }}
                    </button>

                </div>

                <!-- Role form -->
                <div v-if="showRoleForm" class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md mt-4">
                    <h2 class="text-lg font-semibold text-gray-900 dark:text-white mb-2">Add Project</h2>
                    <div class="mb-4">

                        <select v-model="projects" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            
                            <option v-for="option in uniqueToProjectNamesArray" :key="option" :value="option">
                                {{ option }}
                            </option>

                        </select>
                    </div>
                    <div class="flex justify-center">
                        <button @click="addRole" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Add</button>
                    </div>
                </div>
            </div>
        </div>


    </main>

</template>

<script>
    import { ref, onMounted, computed } from "vue";
    import axios from "axios";

    import navBarDashboard_simple from '../../components/DashBoard/navBarDashboard_simple';
    import topBarDashboard_simple from '../../components/DashBoard/topBarDashboard_simple';
    import ProfileAvatar from 'vue-profile-avatar';

    import projectsDirectory from '../../components/Projects/jsonQuestions/projectsDirectory.json';

    // const { userId } = defineProps(); // Accessing userId prop

    const user = ref(null);  // This will store the user's details fetched from the server
    const loading = ref(false);  // This will indicate whether the fetch operation is in progress
    const error = ref(null);  // This will store any error messages

    const showRoleForm = ref(false); 
    const show_buttom_1 = ref(false);
    const projectsListFull = ref(projectsDirectory.questions);
    const projectNamesArray = projectsListFull.value.map(project => project.name);


    const uniqueToProjectNamesArray = ref({
        values: [] // This should be populated with your filtered array values
    });

    ////// es add project not role
    const addRole = () => {
        console.log(`Added role: ${userBio.role}`);
        // Here, you might send the newly added role to the server
        // After successfully adding the role, you might want to close the form
        showRoleForm.value = false;
    };


    export default {
        name: "UserDetails",
        props: ["userId"],


        components: {
            navBarDashboard_simple,
            topBarDashboard_simple,
            ProfileAvatar,
        },

        setup(props) {
            const sortedProjects = ref([]);
            // const user = ref(null);
            const userBio = ref(null);
            const loading = ref(false);
            const error = ref(null);
            const noProject = ref(false);



            onMounted(async () => {


                loading.value = true;
                error.value = null;


                // userDetailsBio

                try {
                    const response2 = await axios.post('https://tvu1u6nd3h.execute-api.us-east-2.amazonaws.com/dev_ops/', {
                        emailR: props.userId,

                    });

                    // If the request was successful, update the user details
                    if (response2.status === 200) {
                        userBio.value = JSON.parse(response2.data.body);
                        // console.log(response2.data);
                    } else {
                        error.value = 'User not found';
                    }

                } catch (e) {
                    error.value = e.message;
                }


                // Projects
                try {
                    const response = await axios.post('https://m989f4oas6.execute-api.us-east-2.amazonaws.com/dev_ops/', {
                        emailR: props.userId,
                    });

                    // If the request was successful, update the user details
                    if (response.data.statusCode === 200) {
                        // user.value = JSON.parse(response.data.body);

                        const projectsObj = JSON.parse(response.data.body);
                        const projectsArray = projectsObj.Projects;
                        sortedProjects.value = projectsArray.sort();
                        
                    }

                    else if (response.data.statusCode === 404) {
                        noProject.value = true;
                    }

                    else {
                        error.value = 'User not found';
                    }
                } catch (e) {
                    error.value = e.message;
                }

                ////

                try {

                    // const arrayFromSet = Array.from(sortedProjects.value);

                    uniqueToProjectNamesArray.value = projectNamesArray.filter(item => !sortedProjects.value.includes(item));

                    console.log(uniqueToProjectNamesArray);


                } catch (error) {
                    console.error("An error occurred:", error);
                }

                //////////

                finally {
                    show_buttom_1.value = true;
                    loading.value = false;
                }
            });

            // console.log("noProject", noProject.value);
            console.log("sortedProjects ", sortedProjects);
            console.log("uniqueToProjectNamesArray ", uniqueToProjectNamesArray);
            return {
                loading, userBio, noProject, sortedProjects, showRoleForm, show_buttom_1, uniqueToProjectNamesArray
            };
        }
    }


</script>

