<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
        <!-- Topbar-->
        <topBarDashboard></topBarDashboard>
        <!-- Sidebar -->
        <navBarDashboard></navBarDashboard>
        
 
    <!-- Core pages -->
        <!-- ########## -->
        <!-- ########## -->
        <main class="p-4 md:ml-64 h-auto pt-20">

            <!-- 0 section -->

            <div class="mx-auto py-8 px-4 sm:px-6 lg:px-8">
                <statsTop></statsTop>
            </div>

            <!-- 1 new section -->
            <div class="flex">
                <div class="flex items-center justify-start min-h-screen bg-gray-50 dark:bg-gray-900 pl-4">
                    <graph_1></graph_1>
                </div>
                <div class="flex items-center justify-start min-h-screen bg-gray-50 dark:bg-gray-900 pl-4">

                    <table_1></table_1>
                
                </div>

            </div>

            <!-- 2 new section -->
            <div class="flex">
                <div class="flex items-center justify-start min-h-screen bg-gray-50 dark:bg-gray-900 pl-4">
                    <graph_2></graph_2>
                </div>
                <div class="flex items-center justify-start min-h-screen bg-gray-50 dark:bg-gray-900 pl-4">



                </div>

            </div>

            <!-- 1 section -->
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">

                <!-- 1 subSection -->
                <div class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-32 md:h-64">

                </div>


                <!-- 2 subSection -->
                <div class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-32 md:h-64">
                </div>

                <!-- 3 subSection -->
                <div class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-32 md:h-64">




                </div>

                <!-- 4 subSection -->
                <div class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-32 md:h-64">
                </div>
            </div>
            <!-- 2 section (Horizontal part) -->

            <div class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-96 mb-4"></div>




            <!-- 3 section -->
            <div class="grid grid-cols-2 gap-4 mb-4">
                <div class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72">

                </div>
                <div class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div>
                <div class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div>
                <div class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div>
            </div>

            <!-- 4 section (Horizontal part) -->
            <div class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-96 mb-4"></div>

            <!-- 5 section -->
            <div class="grid grid-cols-2 gap-4">
                <div class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div>
                <div class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div>
                <div class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div>
                <div class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div>
            </div>
        </main>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import VueCookies from 'vue-cookies';
    import { useRouter } from 'vue-router';
    import VueApexCharts from 'vue-apexcharts';

    import navBarDashboard from '../components/DashBoard/navBarDashboard';
    import topBarDashboard from '../components/DashBoard/topBarDashboard';

    import statsTop from '../components/DashBoard/statsTop';
    import graph_1 from '../components/DashBoard/graph_1';
    import graph_2 from '../components/DashBoard/graph_2';

    import table_1 from '../components/DashBoard/table_1';
    // import VueApexCharts from "vue3-apexcharts";
    

    

    export default {
        components: {
            apexchart: VueApexCharts,
            navBarDashboard,
            topBarDashboard,
            statsTop,
            graph_1,
            graph_2,
            table_1,
        },
        name: 'dashboard_2',
        setup() {
            const router = useRouter();
            const userEmail = VueCookies.get('userEmail');
            const name = VueCookies.get('name') || 'Unknown';


            if (!userEmail) {
                router.push('/login');
            }

            const logout = () => {
                VueCookies.remove('userEmail');
                VueCookies.remove('name');
                router.push('/');
            };



            return { userEmail, name, logout};
        },
    };
</script>

<script setup>


    // ApexCharts options and config
    window.addEventListener("load", function () {
        let options = {
            chart: {
                height: "100%",
                maxWidth: "100%",
                type: "area",
                fontFamily: "Inter, sans-serif",
                dropShadow: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            tooltip: {
                enabled: true,
                x: {
                    show: false,
                },
            },
            fill: {
                type: "gradient",
                gradient: {
                    opacityFrom: 0.55,
                    opacityTo: 0,
                    shade: "#1C64F2",
                    gradientToColors: ["#1C64F2"],
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 6,
            },
            grid: {
                show: false,
                strokeDashArray: 4,
                padding: {
                    left: 2,
                    right: 2,
                    top: 0
                },
            },
            series: [
                {
                    name: "New users",
                    data: [6500, 6418, 6456, 6526, 6356, 6456],
                    color: "#1A56DB",
                },
            ],
            xaxis: {
                categories: ['01 February', '02 February', '03 February', '04 February', '05 February', '06 February', '07 February'],
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                show: false,
            },
        }

        if (document.getElementById("area-chart") && typeof ApexCharts !== 'undefined') {
            const chart = new ApexCharts(document.getElementById("area-chart"), options);
            chart.render();
        }
    });

    /////////////////////////////
    ////////////////////////////

    window.addEventListener("load", function () {
        const getChartOptions = () => {
            return {
                series: [52.8, 26.8, 20.4],
                colors: ["#1C64F2", "#16BDCA", "#9061F9"],
                chart: {
                    height: 420,
                    width: "100%",
                    type: "pie",
                },
                stroke: {
                    colors: ["white"],
                    lineCap: "",
                },
                plotOptions: {
                    pie: {
                        labels: {
                            show: true,
                        },
                        size: "100%",
                        dataLabels: {
                            offset: -25
                        }
                    },
                },
                labels: ["Direct", "Organic search", "Referrals"],
                dataLabels: {
                    enabled: true,
                    style: {
                        fontFamily: "Inter, sans-serif",
                    },
                },
                legend: {
                    position: "bottom",
                    fontFamily: "Inter, sans-serif",
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value + "%"
                        },
                    },
                },
                xaxis: {
                    labels: {
                        formatter: function (value) {
                            return value + "%"
                        },
                    },
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                },
            }
        }

        if (document.getElementById("pie-chart") && typeof ApexCharts !== 'undefined') {
            const chart = new ApexCharts(document.getElementById("pie-chart"), getChartOptions());
            chart.render();
        }
    });

</script>

