<template>
    <header>
        <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
            <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                <a href="/" class="flex items-center">
                    <img src="../images/red_robot-wb.png" class="mr-3 h-6 sm:h-9" alt="Logo" />
                    <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">dgcML</span>
                </a>
                <div>

                    <img id="avatarButton" @click="dropdownOpen = !dropdownOpen" type="button" data-dropdown-toggle="userDropdown" data-dropdown-placement="bottom-start" class="w-10 h-10 rounded-full cursor-pointer" src="https://flowbite.com/docs/images/people/profile-picture-5.jpg" alt="User dropdown">
                    <!--  @click="dropdownOpen = !dropdownOpen" -->
                    <!-- Dropdown menu -->
                    <div id="userDropdown" v-show="dropdownOpen" class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                        <div class="px-4 py-3 text-sm text-gray-900 dark:text-white">
                            <div>{{name}}</div>
                            <div class="font-medium truncate">{{ userEmail }}</div>
                        </div>
                        <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="avatarButton">
                            <li>
                                <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                            </li>
                            <li>
                                <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                            </li>
                            <li>
                                <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                            </li>
                        </ul>
                        <div class="py-1">
                            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" @click="logout">Sign out</a>
                        </div>
                    </div>

                </div>
                <!-- ################### -->
            </div>
        </nav>
    </header>
</template>

<script>
    import VueCookies from 'vue-cookies';
    import { ref, onMounted, computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    export default {
        name: 'dashboard_1',
        setup() {

            const router = useRouter();
            // const store = useStore();
            //const userData = computed(() => store.state.user_data);

            const userEmail = VueCookies.get('userEmail');


            // if not login
            if (!userEmail) {
                router.push('/login');
            }

            const name = VueCookies.get('name') || 'Unknown';
            const dropdownOpen = ref(false);

            // onMounted(() => { userData.value; })


            const logout = () => {
                VueCookies.remove('userEmail');
                VueCookies.remove('name');
                
                router.push('/');
            };

            return { userEmail, name ,logout, dropdownOpen };

        },
    };
</script>
