<template>
    <div class="h-screen pt-20" style="background-color: #7552cc;" >
        <div class="container mx-auto p-4">
            <form @submit.prevent="handleSubmit">
                <div class="mb-8 bg-gray-100 p-6 rounded shadow">
                    <label for="question1">What motivates you to get up in the morning?</label>
                    <textarea id="question1" v-model="question1" placeholder="Your answer..." class="form-input"></textarea>
                </div>
                <div class="mb-8 bg-gray-100 p-6 rounded shadow">
                    <label for="question2">Describe an experience where you learned something important.</label>
                    <textarea id="question2" v-model="question2" placeholder="Your answer..." class="form-input"></textarea>
                </div>
                <div class="mb-8 bg-gray-100 p-6 rounded shadow">
                    <label for="question3">What is your opinion about the role of technology in modern society?</label>
                    <textarea id="question3" v-model="question3" placeholder="Your answer..." class="form-input"></textarea>
                </div>
                <button type="submit" class="submit-button">Submit</button>
            </form>
        </div>
    </div>
</template>

<script>export default {
  data() {
    return {
      question1: "",
      question2: "",
      question3: "",
    };
  },
  methods: {
    async handleSubmit() {
      console.log("Analyzing the text using NLP...");

      console.log("Question 1:", this.question1);
      console.log("Question 2:", this.question2);
      console.log("Question 3:", this.question3);

      // Here, you would send the answers to your NLP system for analysis
      // await axios.post('/api/analyze', { question1: this.question1, question2: this.question2, question3: this.question3 });
    },
  },
};</script>

<!-- Style to mimic Google Forms -->
<style scoped>
    .form-container {
        width: 50%;
        margin: auto;
        padding: 20px;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        border-radius: 10px;
    }

    .form-block {
        margin-bottom: 20px;
    }

    label {
        font-weight: bold;
        margin-bottom: 10px;
    }

    .form-input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .submit-button {
        padding: 10px 20px;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
</style>
