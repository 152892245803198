
<template>

    <head>
        <title>Historia de la Pimienta</title>
    </head>
    <body>

        <div id="app" class="container mx-auto p-4">
            <h1 class="text-4xl font-bold text-green-700 mb-4">La Historia de la Pimienta</h1>

            <div class="history-section bg-gray-100 p-4 rounded mb-4">
                <h2 class="text-2xl font-semibold text-red-600">Antigua Grecia</h2>
                <p class="text-base">Referencias a la pimienta en escritos griegos del siglo IV a.C. Era rara y costosa, pero conocida en la cocina griega de la �lite.</p>
            </div>

            <div class="history-section bg-gray-200 p-4 rounded mb-4">
                <h2 class="text-2xl font-semibold text-red-600">Imperio Romano</h2>
                <p class="text-base">Especia codiciada y cara en la Roma antigua, usada en cocina y medicina. A veces se utiliz� como moneda.</p>
            </div>

            <div class="history-section bg-gray-100 p-4 rounded mb-4">
                <h2 class="text-2xl font-semibold text-red-600">Edad Media</h2>
                <p class="text-base">Especia muy valorada en Europa durante la Edad Media. Su alto costo la hac�a un lujo para los ricos y poderosos.</p>
            </div>

            <div class="history-section bg-gray-200 p-4 rounded mb-4">
                <h2 class="text-2xl font-semibold text-red-600">Era de la Exploraci�n</h2>
                <p class="text-base">Motivaci�n principal para las exploraciones y colonizaciones europeas. Portugal y Espa�a lideraron inicialmente, seguidos por Inglaterra y los Pa�ses Bajos.</p>
            </div>

            <div class="history-section bg-gray-100 p-4 rounded mb-4">
                <h2 class="text-2xl font-semibold text-red-600">Impacto Global</h2>
                <p class="text-base">Su influencia no solo afect� la cocina y la medicina, sino que tambi�n tuvo un impacto en la historia y la geopol�tica mundiales.</p>
            </div>
        </div>


    </body>
</template>

<script>
    import VueCookies from 'vue-cookies';
    import { ref, onMounted, computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { initFlowbite } from 'flowbite';

    export default {
        name: 'dashboard_1',
        setup() {

            const router = useRouter();
            // const store = useStore();
            //const userData = computed(() => store.state.user_data);

            const userEmail = VueCookies.get('userEmail');


            // if not login
            if (!userEmail) {
                router.push('/login');
            }

            const name = VueCookies.get('name') || 'Unknown';
            const dropdownOpen = ref(false);

            // onMounted(() => { userData.value; })


            const logout = () => {
                VueCookies.remove('userEmail');
                VueCookies.remove('name');

                router.push('/');
            };

            return { userEmail, name, logout, dropdownOpen };

        },
    };

    onMounted(() => {
        initFlowbite();
    });

</script>

    


