import { createRouter, createWebHistory } from 'vue-router';
import home from '../views/home.vue';

import products from '../views/products.vue'
import products_details from '../views/products_details.vue'

import login from '../views/login.vue'
import terms from '../views/terms.vue'

import NotFound from '../components/notFound.vue'

//import team from '../views/team.vue'
import solutions from '../views/solutions.vue'

import contact_us from '../views/contact_us.vue'
import successEmail from '../views/utils/successEmail.vue'

import dashboard_1 from '../views/dashboard_1.vue'
import dashboard_2 from '../views/dashboard_2.vue'

import dashboard_solutions from '../views/dashboard_solutions.vue'
import myProjects from '../views/myProjects.vue'

import messages from '../views/messages.vue'
import help from '../views/help.vue'
import documents from '../views/documents.vue'

import project_1 from '../components/Projects/public/project_1.vue'
import project_2 from '../components/Projects/public/project_2.vue'
import project_3 from '../components/Projects/public/project_3.vue'

import project_4 from '../components/Projects/public/project_4.vue'
import project_5 from '../components/Projects/public/project_5.vue'

// Project
import Project from "../views/project.vue"
import ProjectHome from "../views/projectHome.vue"
//import AccessQuestionnaire from "../views/AccessQuestionnaire.vue";
//import ReplyQuestionnaire from "../components/Projects/replyQuestionnaire.vue";
import project_talk from '../components/Projects/nonpublic/project_talk.vue'



// Results
import result from "../views/results.vue"
import resultHome from "../views/resultHome.vue"
import AccessQuestionnaire from "../views/AccessQuestionnaire.vue";
// import ReplyQuestionnaire from "../components/Projects/replyQuestionnaire.vue";


// References - not displayed
import borrar from '../views/borrar.vue'
import inbox from '../views/inbox.vue'

// Users creators
import memberListCreator from '../views/memberListCreator.vue'

// Developer pages
import constructionTimeLine from '../views/developer/constructionTimeLine.vue'
import contactUsMessages from '../views/developer/contactUsMessages.vue'
import signup_dev_creators from '../views/developer/signup_dev_creators.vue'

// Members
import memberList from '../views/members/memberList.vue'
import UserDetails from '../views/members/UserDetails.vue'
import modifyList from '../views/members/modifyList.vue'
import modifyDetails from '../views/members/modifyDetails.vue'

import login_google from '../views/login_google.vue'



const routes = [

    { path: '/', component: home, name: 'home' },

    { path: '/products', component: products, name: 'products' },
    { path: '/products_details/:id', component: products_details, name: 'products_details', props: true },

    { path: '/login', component: login, name: 'login' },

    { path: '/terms', component: terms, name: 'terms' },
    //{ path: '/team', component: team, name: 'team' },
    { path: '/solutions', component: solutions, name: 'solutions' },

    { path: '/contact_us', component: contact_us, name: 'contact_us' },
    { path: '/successEmail', component: successEmail, name: 'successEmail' },

    { path: '/dashboard_1', component: dashboard_1, name: 'dashboard_1' },
    { path: '/dashboard_2', component: dashboard_2, name: 'dashboard_2' },
    { path: '/messages', component: messages, name: 'messages' },

    { path: '/inbox', component: inbox, name: 'inbox' },
    { path: '/borrar', component: borrar, name: 'borrar' },
    { path: '/dashboard_solutions', component: dashboard_solutions, name: 'dashboard_solutions' },
    { path: '/myProjects', component: myProjects, name: 'myProjects' },
    { path: '/:pathMatch(.*)*', component: NotFound, name: 'NotFound' },
    { path: '/help', component: help, name: 'help' },
    { path: '/documents', component: documents, name: 'documents' },

    //Project
    {path: "/project/:id", component: Project, name: "Project" },
    {path: "/ProjectHome", component: ProjectHome, name: "ProjectHome"},
    //{path: "/accessQuestionnaire", name: "accessQuestionnaire", component: AccessQuestionnaire},
    //{path: "/reply-questionnaire/:projectName", name: "reply-questionnaire", component: ReplyQuestionnaire, props: true },
    { path: "/project_1", component: project_1, name: project_1 },
    { path: "/project_2", component: project_2, name: project_2 },
    { path: "/project_3", component: project_3, name: project_3 },

    { path: "/project_4", component: project_4, name: project_4 },
    { path: "/project_5", component: project_5, name: project_5 },

    { path: "/project_talk", component: project_talk, name: project_talk },
    
    

    //Results
    { path: "/result/:id", component: result, name: "result" },
    { path: "/resultHome", component: resultHome, name: "resultHome" },
    { path: "/accessQuestionnaire", name: "accessQuestionnaire", component: AccessQuestionnaire },
    //{ path: "/reply-questionnaire/:projectName", name: "reply-questionnaire", component: ReplyQuestionnaire, props: true },

    // Users
    { path: '/memberListCreator', component: memberListCreator, name: 'memberListCreator' },

    // Developer pages
    { path: '/developer/constructionTimeLine', component: constructionTimeLine, name: 'constructionTimeLine' },
    { path: '/developer/contactUsMessages', component: contactUsMessages, name: 'contactUsMessages' },
    { path: '/developer/signup_dev_creators', component: signup_dev_creators, name: 'signup_dev_creators' },

    // Members page
    { path: '/memberList', component: memberList, name: 'memberList' },
    { path: '/userDetails/:userId', component: UserDetails, name: 'userDetails', props: true },
    { path: '/modifyList', component: modifyList, name: 'modifyList' },
    { path: '/modifyDetails/:userId', component: modifyDetails, name: 'modifyDetails', props: true },

{ path: '/login_google', component: login_google, name: 'login_google' },


];

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
});





export default router;



