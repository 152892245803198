<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
        <!-- Topbar-->
        <topBarDashboard_simple></topBarDashboard_simple>
        <!-- Sidebar -->
        <navBarDashboard_simple></navBarDashboard_simple>

        <!-- Core pages -->
        <!-- ########## -->
        <!-- ########## -->

        <main class="p-4 md:ml-64 h-auto pt-20">

            <ol class="relative border-l border-gray-200 dark:border-gray-700">

                <!-- 31 July 2023 -->
                <li class="mb-10 ml-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">31 July 2023</time>
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Dashboard</h3>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Create dashboard for developers and creators.

                        <span class="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                            <span class="w-2 h-2 mr-1 bg-green-500 rounded-full"></span>
                            Done
                        </span>
                    </p>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Create a specific and different dashboard for developers and creators.

                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>
                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Define the nav bar components.

                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>
                </li>


                <!-- 01 August 2023 -->
                <li class="mb-10 ml-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">01 August 2023</time>
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Construction of member list</h3>
                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Create section to register Members: Developers, Creators and Users.
                        <span class="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                            <span class="w-2 h-2 mr-1 bg-green-500 rounded-full"></span>
                            Done
                        </span>
                    </p>


                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Improve pagination.
                        <span class="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                            <span class="w-2 h-2 mr-1 bg-yellow-500 rounded-full"></span>
                            Later
                        </span>
                    </p>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Upload user profile picture.
                        <span class="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                            <span class="w-2 h-2 mr-1 bg-yellow-500 rounded-full"></span>
                            Later
                        </span>
                    </p>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Create section to view members.

                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Display picture, if there is no picture show avatar.
                        <span class="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                            <span class="w-2 h-2 mr-1 bg-yellow-500 rounded-full"></span>
                            Later
                        </span>
                    </p>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Create section to modify members.
                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Create a help notification.

                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>
                </li>

                <!-- 04 August 2023 -->
                <li class="mb-10 ml-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">04 August 2023</time>
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Messages</h3>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Display a section to read all the messages.
                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Create message between creators and users.

                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        E-mail to all the users.

                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Message notification.

                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>
                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Contact us messages (Developer).

                        <span class="inline-flex items-center bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                            <span class="w-2 h-2 mr-1 bg-yellow-500 rounded-full"></span>
                            Later
                        </span>
                    </p>
                </li>

                <!-- 05 August 2023 -->
                <li class="mb-10 ml-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">05 August 2023</time>
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">My profile, my research</h3>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Display a section to create a personal profile.
                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    
                    <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                        <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                        Not done
                    </span>
                    </p>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Display a section to create a personal research page.
                    <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                        <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                        Not done
                    </span>
                    </p>

                </li>

                <!-- 06 August 2023 -->
                <li class="mb-10 ml-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">06 August 2023</time>
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Research form</h3>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Display a section to create a research form.
                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>

                </li>

                <!-- 07 August 2023 -->
                <li class="mb-10 ml-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">07 August 2023</time>
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Results of research</h3>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Display a section to create a general view of the result.
                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>
                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Display a section to create a detailed view of the results.
                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>

                </li>

                <!-- 08 August 2023 -->
                <li class="mb-10 ml-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">08 August 2023</time>
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Others</h3>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        My projects page (Historical process).
                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>
                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Documents page.
                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>
                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Language.
                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>
                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Page settings.
                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>
                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Notifications.
                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>
                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Search bar.
                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>

                </li>

                <!-- 09 August 2023 -->
                <li class="mb-10 ml-4">
                    <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">09 August 2023</time>
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Security</h3>

                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Secure access to developer mode.
                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>
                    <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Create e-mail authentification.

                        <span class="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                            <span class="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
                            Not done
                        </span>
                    </p>

                </li>

            </ol>

        </main>
        <!-- ########## -->
        <!-- ########## -->
    </div>
</template>

<script>
    import VueCookies from 'vue-cookies';
    import { useRouter } from 'vue-router';
    import { ref, onMounted, computed } from 'vue';


    import navBarDashboard_simple from '../../components/DashBoard/navBarDashboard_simple';
    import topBarDashboard_simple from '../../components/DashBoard/topBarDashboard_simple';

    // import footer_template from '../../components/footer_template';


    export default {
        components: {
            navBarDashboard_simple,
            topBarDashboard_simple,
            
        },
        name: 'constructionTimeLine',
        setup() {
            const router = useRouter();
            const userEmail = VueCookies.get('userEmail');
            const name = VueCookies.get('name') || 'Unknown';

            onMounted(() => {
                initFlowbite();
            });

            if (!userEmail) {
                router.push('/login');
            }

            const logout = () => {
                VueCookies.remove('userEmail');
                VueCookies.remove('name');
                router.push('/');
            };



            return { userEmail, name, logout };
        },

    };
</script>

