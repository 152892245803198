<template>
    <resultsDetails :id="this.$route.params.id" />
</template>

<script>import resultsDetails from "../components/Projects/resultsDetails.vue";

export default {
  components: {
    resultsDetails
  }
};</script>