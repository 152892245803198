
<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
        <!-- Topbar-->
        <topBarDashboard_simple></topBarDashboard_simple>
        <!-- Sidebar -->
        <navBarDashboard_simple></navBarDashboard_simple>

        <!-- Core pages -->
        <main class="p-4 md:ml-64 h-screen pt-20">
            <div>
                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white"> {{ project.name }}</h2>
                <p class="mb-2 text-base font-medium text-gray-700 dark:text-gray-300">{{ project.description }}</p>
                <Stats :projectName="project.name" :questions="questions" />
            </div>
        </main>
    </div>
</template>

<script>
    import navBarDashboard_simple from '../../components/DashBoard/navBarDashboard_simple';
    import topBarDashboard_simple from '../../components/DashBoard/topBarDashboard_simple';
    import Stats from "../../components/Projects/Stats.vue";
    import questionsProject1 from './jsonQuestions/question_1.json';
    import questionsProject2 from './jsonQuestions/question_2.json';

    export default {
        components: {
            Stats,
            navBarDashboard_simple,
            topBarDashboard_simple,
        },
        props: ["id"],
        data() {
            return {
                project: null,
                questions: []
            };
        },
        async created() {
            const projects = [
                { id: 1, name: "Project 1", description: questionsProject1["description"], questions: questionsProject1["questions"] },
                { id: 2, name: "Project 2", description: questionsProject2["description"], questions: questionsProject2["questions"] }
            ];

            this.project = projects.find(p => p.id === parseInt(this.id));
            this.questions = this.project.questions;
        }
    };
</script>
