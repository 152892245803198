<!-- Questionnaire.vue -->
<template>
    <div>
        <table class="min-w-full bg-white dark:bg-gray-800">
            <thead class="dark:bg-gray-700 dark:text-white">
                <tr>
                    <th class="text-left py-3 px-4 uppercase font-semibold text-sm">Question</th>
                    <th class="text-left py-3 px-4 uppercase font-semibold text-sm">Label</th>
                    <th class="text-left py-3 px-4 uppercase font-semibold text-sm">Type</th>
                </tr>
            </thead>
            <tbody class="text-gray-700 dark:text-gray-400">
                <tr v-for="(question, index) in questions" :key="question.name">
                    <td class="text-left py-3 px-4">{{ index + 1 }}</td>
                    <td class="text-left py-3 px-4">{{ question.label }}</td>
                    <td class="text-left py-3 px-4">{{ question.type }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: ['projectName', 'questions']
    };
</script>
