<template>
    <div v-if="product" class="product-details">
        <img :src="product.imageSrc" :alt="product.imageAlt" />
        <h2>{{ product.name }}</h2>
        <p>{{ product.color }}</p>
        <p>{{ product.price }}</p>
    </div>
    <div v-else>Loading...</div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { useRoute } from 'vue-router'
    import { getProduct } from '../api' // Replace with your actual API call

    const route = useRoute()
    const product = ref(null)

    onMounted(async () => {
        const productId = route.params.id
        product.value = await getProduct(productId)
    })
</script>
