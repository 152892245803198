<template>

    <header_template />

    <section class="bg-white dark:bg-gray-900 mx-4 lg:mx-8">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6  ">
            <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white ">Autonomous Web Explorers: Navigating and Learning with LLMs</h2>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">This initiative merges web exploration and knowledge assimilation by harnessing autonomous agents equipped with state-of-the-art Language Learning Models (LLMs), alongside versatile AI development frameworks such as PyTorch and LangChain. </p>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">These agents possess the unique capability to independently navigate the internet, engage in complex dialogues, and process extensive datasets. Central to this innovation is the agents' proficiency in autonomous learning and their ability to construct dynamic knowledge bases. This is achieved through the integration of vector space models and traditional databases, optimizing the organization and retrieval of information. The incorporation of serverless architectures further enhances the system's scalability and efficiency, ushering in an advanced era of smart, self-governing web exploration and data management.</p>
            </div>
            <div class="flex justify-center items-center">
                <!-- This div becomes a flex container -->
                <img class="mt-4 w-4/6 lg:mt-10 rounded-lg" src="../images/dalle_red_robot_sail.png" alt="Conversational Agent">
            </div>
        </div>

        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6  shadow-md">
            <div class="flex justify-center items-center">
                <!-- This div becomes a flex container -->
                <img class="mt-4 w-4/6 lg:mt-10 rounded-lg" src="../images/dalle_robot_caja.png" alt="Conversational Agent">
            </div>

            <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Conversational Agents: Advancing Human Interaction with RetrievalQA and Vector Embeddings</h2>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">This initiative is at the forefront of enhancing human-computer interaction by developing conversational agents that leverage RetrievalQA systems with LLMs. These systems enable the agents to extract precise information from vast datasets, which is then transformed into vector embeddings for sophisticated understanding and responses.</p>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">The agents are equipped with the capability to learn continuously from interactions, improving their language models and embedding knowledge into a contextually aware vector space. This empowers them to engage in meaningful dialogues, providing accurate and context-relevant answers. The vector embeddings allow for nuanced understanding beyond keyword matching, leading to a more natural and human-like conversation. The integration of these technologies signifies a leap towards more intuitive and intelligent digital assistants, capable of fluid and informative interactions with users.</p>
            </div>


        </div>

    </section>






</template>

<script setup>
    import header_template from '../components/header_template.vue'
    import footer_template from '../components/footer_template.vue'
</script>

<script>
    export default {
        name: 'solutions',
        components: {
            header_template,
            footer_template,
        }
    }</script>