<template>
    <ProjectDetails :id="this.$route.params.id" />
</template>

<script>import ProjectDetails from "../components/Projects/ProjectDetails.vue";

export default {
  components: {
    ProjectDetails
  }
};</script>
