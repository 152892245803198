<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
        <!-- Topbar -->
        <topBarDashboard_simple></topBarDashboard_simple>
        <!-- Sidebar -->
        <navBarDashboard_simple></navBarDashboard_simple>

        <!-- Core pages -->

        <main class="p-4 md:ml-64 h-screen pt-20">
            <!-- Results -->

            <a href="/project_1">Project 1</a>

        </main>
    </div>
</template>

<script>
    import navBarDashboard_simple from '../components/DashBoard/navBarDashboard_simple';
    import topBarDashboard_simple from '../components/DashBoard/topBarDashboard_simple';
    import resultsList from "../components/Projects/resultsList.vue";

    export default {
        components: {
            resultsList,
            navBarDashboard_simple,
            topBarDashboard_simple,
        }
    };</script>
