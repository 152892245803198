// api.js
export const products = [
    {
        id: '1',
        name: 'Basic Tee',
        href: '/',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        color: 'Black',
    },
    {
        id: '2',
        name: 'Basic Tee',
        href: '/product/2',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-02.jpg',
        imageAlt: "Front of men's Basic Tee in white.",
        price: '$35',
        color: 'White',
    },
    {
        id: '3',
        name: 'Basic Tee',
        href: '/product/3',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-03.jpg',
        imageAlt: "Front of men's Basic Tee in red.",
        price: '$35',
        color: 'Red',
    },
    // ...more products
]

export function getProduct(id) {
    return new Promise((resolve, reject) => {
        const product = products.find(p => p.id === id)
        if (product) {
            // Simulate an API call with setTimeout
            setTimeout(() => {
                resolve(product)
            }, 1000)
        } else {
            reject(new Error('Product not found'))
        }
    })
}
