<template>

    <!-- Topbar-->
    <topBarDashboard_simple></topBarDashboard_simple>
    <!-- Sidebar -->
    <navBarDashboard_simple></navBarDashboard_simple>

    <main class="p-4 md:ml-64 h-auto pt-20 bg-gray-50 dark:bg-gray-900 min-h-screen">

        <div v-if="loading" class="px-3 py-1 text-xs font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200">loading...</div>


        <div class="p-6 max-w-lg mx-auto">



            <div v-if="userBio" class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                <h1 class="text-2xl font-bold mb-6 text-gray-900 dark:text-white">User Details</h1>
                <div class="mb-4">
                    <span class="text-gray-600 dark:text-gray-400">Name:</span> <span class="text-gray-900 dark:text-white">{{ userBio.name + ' ' + userBio.lastName  }}</span>
                </div>
                <div class="mb-4">
                    <span class="text-gray-600 dark:text-gray-400">Role:</span> <span class="text-gray-900 dark:text-white">{{ userBio.role }}</span>
                </div>
                <div class="mb-4">
                    <span class="text-gray-600 dark:text-gray-400">Registered:</span> <span class="text-gray-900 dark:text-white">{{ userBio.time_stamp }}</span>
                </div>
            </div>
        </div>

        <!--- Projects --->
        <div class="p-6 max-w-lg mx-auto">

            <div v-if="sortedProjects && sortedProjects.length && noProject !== true" class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">



                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Projects:</h2>
                <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400" v-for="project in sortedProjects" :key="project">

                        <li>{{ project }}</li>

                </ul>

            </div>

    <div v-if="noProject === true && userBio" class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <h1 class="text-2xl font-bold mb-6 text-gray-900 dark:text-white">Projects</h1>
        <p class="text-gray-900 dark:text-white">{{ userBio.name + ' ' + userBio.lastName  }} has no projects associated.</p>
    </div>
    </div>

    </main>

</template>

<script>
    import { ref, onMounted, computed } from "vue";
    import axios from "axios";

    import navBarDashboard_simple from '../../components/DashBoard/navBarDashboard_simple';
    import topBarDashboard_simple from '../../components/DashBoard/topBarDashboard_simple';
    import ProfileAvatar from 'vue-profile-avatar';

    // const { userId } = defineProps(); // Accessing userId prop

    const user = ref(null);  // This will store the user's details fetched from the server
    const loading = ref(false);  // This will indicate whether the fetch operation is in progress
    const error = ref(null);  // This will store any error messages




    export default {
        name: "UserDetails",
        props: ["userId"],


        components: {
            navBarDashboard_simple,
            topBarDashboard_simple,
            ProfileAvatar,
        },

        setup(props) {
            const sortedProjects = ref([]);
            // const user = ref(null);
            const userBio = ref(null);
            const loading = ref(false);
            const error = ref(null);
            const noProject = ref(false);



            onMounted(async () => {
                

                loading.value = true;
                error.value = null;


                // userDetailsBio

                try {
                    const response2 = await axios.post('https://tvu1u6nd3h.execute-api.us-east-2.amazonaws.com/dev_ops/', {
                        emailR: props.userId,

                    });

                    // If the request was successful, update the user details
                    if (response2.status === 200) {
                        userBio.value = JSON.parse(response2.data.body);
                        // console.log(response2.data);
                    } else {
                        error.value = 'User not found';
                    }

                } catch (e) {
                    error.value = e.message;
                }






                // Projects
                try {
                    const response = await axios.post('https://m989f4oas6.execute-api.us-east-2.amazonaws.com/dev_ops/', {
                        emailR: props.userId,

                    });

                    // If the request was successful, update the user details
                    if (response.data.statusCode === 200) {
                        // user.value = JSON.parse(response.data.body);

                        const projectsObj = JSON.parse(response.data.body);
                        const projectsArray = projectsObj.Projects;
                        sortedProjects.value = projectsArray.sort();

                        // console.log("projects.value ", sortedProjects.value);
                    }

                    else if (response.data.statusCode === 404) {
                        noProject.value = true;
                    }

                    else {
                        error.value = 'User not found';
                    }
                } catch (e) {
                    error.value = e.message;
                }



                finally {
                    loading.value = false;
                }
            });


            // console.log("noProject", noProject.value);
            return {
                loading, userBio, noProject, sortedProjects
            };
        }
    }


</script>

