
<template>

    <header_template />
    <section_home />

</template>

<script setup>
    import { onMounted } from 'vue';
    import { initFlowbite } from 'flowbite';
    
    import section_home from '../components/section_home.vue'
    import footer_template from '../components/footer_template.vue'

    import header_template from '../components/header_template.vue'

    onMounted(() => {
        initFlowbite();
    });

</script>

<script>
    export default {
        components: {
            header_template,
            section_home,
            footer_template

        }
    }
</script>
