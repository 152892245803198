<template>
    <div class="relative">
        <!-- Superior Div for displaying the answers -->
        <div class="absolute top-0 left-0 w-full">
            <div v-for="index in Object.keys(gpt4Responses)" :key="'past-' + index" class="p-4 border-b">
                <div style="background-color: #edf6f9;" class="p-2 rounded">
                    <p><strong>You asked:</strong> {{ userInput[index] }}</p>
                </div>
                <div style="background-color: #83c5be;" class="p-2 rounded mt-2">
                    <p><strong>Server says:</strong> {{ gpt4Responses[index] }}</p>
                </div>
            </div>
        </div>

        <!-- Fixed gray div at the bottom for the form -->
        <!-- Fixed div at the bottom for the form with the color #006d77 #264653 -->
        <div v-if="activeFormIndex <= 3" class="fixed bottom-0 left-0 w-full p-4" style="background-color: #264653;">
            <form @submit.prevent="handleSubmit(activeFormIndex)">
                <label :for="'userInput' + activeFormIndex" class="text-white">Ask a question:</label>
                <input :id="'userInput' + activeFormIndex" v-model="userInput[activeFormIndex]" class="p-2 border rounded" />
                <button type="submit" class="bg-blue-500 text-white p-2 rounded">Submit</button>
            </form>
        </div>


        <div v-else class="fixed bottom-0 left-0 w-full bg-gray-300 p-4 text-white" style="background-color: #7552cc; ">

            <p>All forms have been submitted.</p>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                activeFormIndex: 1, // Start with the first form
                userInput: {},
                gpt4Responses: {},
                dummyGPT4Data: {
                    1: "I'm fine, thank you. How about you?",
                    2: "My favorite color is blue.",
                    3: "Yes, I love coding. It's a way to solve problems and be creative."
                }
            };
        },
        methods: {
            handleSubmit(index) {
                this.gpt4Responses[index] = this.dummyGPT4Data[index];
                // Move on to the next form
                if (this.activeFormIndex < 3) {
                    this.activeFormIndex++;
                } else {
                    this.activeFormIndex = 4; // Indicate that all forms have been submitted
                }
            }
        }
    };
</script>
